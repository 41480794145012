import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const EditModule = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [user, setUser] = useState({});
  const [courses, setCourses] = useState([]);
  const [images, setImages] = useState();
  const [newImage, setNewImage] = useState(null);
  const [loading, setLoading] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const result = await axios.get('https://innovationschool.io:5000/api/courses', {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setCourses(result.data);
      } catch (error) {
        if (error.response?.status === 401) {
          navigate('/');
        }
      }
    };
    fetchCourses();
  }, [navigate]);

  useEffect(() => {
    const fetchModule = async () => {
      try {
        const result = await axios.get(`https://innovationschool.io:5000/api/module?id=${id}`, {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        const data = result.data;
        setUser(data);
        setImages(data.image);
      } catch (error) {
        if (error.response?.status === 401) {
          navigate('/');
        }
      }
    };
    fetchModule();
  }, [id, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };


  const handleFileChangeAndUpload = async (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      alert('Please select a file first.');
      return;
    }

    setLoading(true); // Start loading indicator
    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await axios.post('https://innovationschool.io:5000/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setUser((prevUser) => ({ ...prevUser, image: response.data.fileUrl })); // Update user with uploaded image URL
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setLoading(false); // Stop loading indicator
    }
  };



  const handleRemoveImage = (substring) => {
    setImages((prevImages) => prevImages.replace(substring, ''));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('image', newImage);


    try {
      const response = await axios.put(`https://innovationschool.io:5000/api/module/${id}`, user, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      alert('Course updated successfully');
      // navigate('/dashboard');
    } catch (error) {
      alert('Failed to update course');
    }
  };

  return (
    <div className="bg-gray-50 min-h-screen flex flex-col items-center py-8">
      <div className="w-full max-w-4xl bg-white shadow-lg rounded-lg p-6">
        <h1 className="text-2xl font-bold text-gray-700 mb-6">Edit Course</h1>
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Images Section */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Photos (1000 X 1000 px)
            </label>
            <div className="flex flex-wrap gap-4 mb-4">
              {/* {images.map((img, index) => ( */}
              {/* <div  className="relative w-32 h-32">
                  <img
                    src={images}
                    alt={`Preview`}
                    className="w-full h-full object-cover rounded-md"
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveImage(0)}
                    className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
                  >
                    ✕
                  </button>
                </div> */}
              {/* ))} */}
              {(user.image || selectedImage) && (
                <img
                  src={selectedImage || user.image}
                  alt="Preview"
                  className="ml-4 h-20 w-20 object-cover rounded-md border border-gray-300"
                />
              )}

            </div>
            <input
              type="file"
              onChange={handleFileChangeAndUpload}
              accept="image/*"
              className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded file:border file:border-gray-300 file:text-sm file:font-medium file:bg-gray-100 file:hover:bg-gray-200"
            />
            {/* <span className="bg-blue-400 p-2 m-3 mt-5 rounded-md cursor-pointer" onClick={onUpload}>{loading ? (
              <svg className="animate-spin h-5 w-5 mr-2 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
              </svg>
            ) : 'Upload'}
            </span> */}
          </div>

          {/* Title */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Title</label>
            <input
              type="text"
              name="title"
              value={user.title || ''}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"
            />
          </div>

          {/* Course Dropdown */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Course</label>
            <select
              name="course"
              value={user.course || ''}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"
            >
              <option value="" disabled>
                Select a course
              </option>
              {courses.map((item, index) => (
                <option key={index} value={item.title}>
                  {item.title}
                </option>
              ))}
            </select>
          </div>

          {/* Tag */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Tag</label>
            <input
              type="text"
              name="tag"
              value={user.tag || ''}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"
            />
          </div>

          {/* Description */}
          {/* <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Price</label>
            <input
            type='text'
              name="description"
              value={user.description || ''}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"
              rows="4"
            />
          </div> */}

          <button
            type="submit"
            className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Update
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditModule;
